import consumer from "./consumer"

$(document).on('turbolinks:load', function() {
  const pollId = $('section#poll').data('poll-id');
  const updateChart = $('section#poll').data('poll-update-chart');
  if(pollId) {
    consumer.subscriptions.create({ channel: "PollsChannel", id: pollId }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        addVote(data['name'], data['avatar'], data['results']);
        if (updateChart == '1') {
          updateChart(data['results']);
        }
      }
    });
  };
});